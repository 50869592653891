import { isServer } from '../index'

class ClientCodeRunningOnServerError extends Error {
	constructor() {
		super('This can only run client side.')
		this.name = 'ClientCodeRunningOnServerError'
	}
}
export const assertRunningOnClient = () => {
	if (isServer()) throw new ClientCodeRunningOnServerError()
}
