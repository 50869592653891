import { isServer } from 'lib'
import { getCookie, removeCookie, setCookie } from 'services/common/Cookies'
import { UserType } from 'services/Authentication'
import Logger from 'services/Logger'
import { decode, encode } from 'lib/strings/encode'

// there is no refresh token cookie because that would be insecure. Either the client or the server owns refreshing.
export const ACCESS_TOKEN_COOKIE = 'fc_accessToken'
export const ID_TOKEN_COOKIE = 'fc_idToken'
export const REFRESH_TOKEN_LOCALSTORAGE = 'fc_refreshToken'
export const FIRST_LOAD_COOKIE = 'fc_first'
export const DISPLAY_ID_COOKIE = 'fc_idDisplay'

export interface UserIDDisplay {
	id?: string
	status: UserType
	name?: string
}

export function isFirstLoad(): boolean {
	if (isServer()) return true
	const firstLoadCookie = getCookie(FIRST_LOAD_COOKIE)
	if (firstLoadCookie === undefined) {
		setCookie(FIRST_LOAD_COOKIE, new Date().toISOString())
	}
	return firstLoadCookie === undefined
}

export const storeIdentity = (status: UserType, name?: string, id?: string): void => {
	const value = {
		status,
		name,
		id,
		date: new Date().toISOString()
	}
	const cookieVal = encode(JSON.stringify(value))
	setCookie(DISPLAY_ID_COOKIE, cookieVal)
}

export const clearDisplayIDCookies = () => {
	const cookieList: Array<string> = [DISPLAY_ID_COOKIE]
	cookieList.forEach(cookie => {
		try {
			removeCookie(cookie)
		} catch (e) {}
	})
}

export const getIdentityFromCookie = (): UserIDDisplay | undefined => {
	try {
		const cookieVal = getCookie(DISPLAY_ID_COOKIE)
		if (cookieVal) {
			return JSON.parse(decode(cookieVal))
		} else {
			return undefined
		}
	} catch (err) {
		Logger.error(err)
		return { status: 'unknown' }
	}
}
