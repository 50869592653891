import ExternalAuthState from 'services/Authentication/ExternalAuthState'
import { KeycloakLogger } from 'services/Logger'
import { addDDTiming } from 'services/AnalyticsLite/Datadog/events'

export const createKeycloakObserver = (shouldInit: () => boolean): PerformanceObserverCallback => {
	return (entryList, observer) => {
		addDDTiming('performance_observer_fired')
		if (shouldInit()) {
			KeycloakLogger.debug('requesting init from observer')
			ExternalAuthState.requestInitOnce().then(() => {
				KeycloakLogger.debug('request returned')
			})
			observer.disconnect()
		} else {
			KeycloakLogger.debug('disconnecting cause we already have what we need')
			observer.disconnect()
		}
	}
}
