export const MEASUREMENT_ID = 'G-CYLP31QT7F'
export const GTM_HOST = 'fiona.com'
// export const GTAG_SRC = '/inline/analytics/gtag.js' //`https://fiona.com/gtag/js?id=${MEASUREMENT_ID}`
export const GTAG_INIT_JS =
	'window.dataLayer = window.dataLayer || []; window.gtag = function gtag() {window.dataLayer.push(arguments)}'

export const GTM_WEB_ID = 'GTM-W7M58P6'
export const GTM_INIT = `<!-- Google Tag Manager -->
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://${GTM_HOST}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_WEB_ID}');
<!-- End Google Tag Manager -->`
