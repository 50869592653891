import React from 'react'
import { GA4Scripts } from 'services/AnalyticsLite/GA4'
import { GoogleAdsInit } from './Ads'

export const AnalyticsProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	return (
		<>
			<GA4Scripts />
			<GoogleAdsInit />
			{children}
		</>
	)
}
