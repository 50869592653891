import { UnleashContext } from 'pages/api/web/feature'
import { KeycloakInstance } from 'keycloak-js'
import { getCookie, setCookie } from 'services/common/Cookies'
import { ID, isServer } from 'lib'
import { getIdentityFromCookie } from 'services/Authentication/Utils/Cookies'

const UNLEASH_SESSION_COOKIE = 'fc_unleash_session'
const UNLEASH_LOCAL_STORAGE = 'fc_unleash_cache'

interface FeatureCache {
	[x: string]: boolean
}

class ExperimentState {
	private static instance: ExperimentState
	private context: UnleashContext
	private contextCacheKey: string

	private constructor() {
		const Identity = getIdentityFromCookie()
		const sessionID = getCookie(UNLEASH_SESSION_COOKIE) || setCookie(UNLEASH_SESSION_COOKIE, ID(), { expires: 10000 })
		this.context = {
			userId: Identity?.id,
			sessionId: sessionID,
			properties: { internal: 'external', userType: Identity?.status === 'loggedIn' ? 'standard' : 'guest' }
		}
		this.contextCacheKey = ID()
	}

	private getFeaturesFromCache = () => {
		if (!isServer()) {
			const current = localStorage.getItem(UNLEASH_LOCAL_STORAGE)
			const object: FeatureCache = current ? JSON.parse(current) : {}
			return object
		}
		return {}
	}

	public getFeatureFromCache = (feature: string) => {
		const current = this.getFeaturesFromCache()
		return current[feature] || false
	}

	public storeFeature = (feature: string, enabled: boolean) => {
		const current = this.getFeaturesFromCache()
		if (current[feature] != enabled) {
			current[feature] = enabled
			if (!isServer()) {
				localStorage.setItem(UNLEASH_LOCAL_STORAGE, JSON.stringify(current))
			}
		}
	}

	public getContextKey = () => {
		return this.contextCacheKey
	}

	public getContext(): UnleashContext {
		return this.context
	}

	public updateContextFromKeycloak(keycloak: KeycloakInstance): void {
		this.context = {
			...this.context,
			userId: keycloak.subject,
			properties: {
				roles: keycloak.realmAccess?.roles?.join(','),
				internal: keycloak.hasRealmRole('internal') ? 'internal' : 'external',
				userType: keycloak.hasRealmRole('standard') ? 'standard' : 'guest'
			}
		}
		this.contextCacheKey = ID()
	}

	public static getInstance(): ExperimentState {
		if (!ExperimentState.instance) {
			ExperimentState.instance = new ExperimentState()
		}

		return ExperimentState.instance
	}
}

export const Experiments = ExperimentState.getInstance()
