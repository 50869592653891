import { MutableRefObject, useRef } from 'react'

const INIT_VALUE = Symbol('init_value')

export const useLazyRef = <T>(fn: () => T) => {
	const ref = useRef<T | symbol>(INIT_VALUE)
	if (ref.current === INIT_VALUE) {
		ref.current = fn()
	}
	return ref as MutableRefObject<T>
}
