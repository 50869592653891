import { useAuthState } from 'services/Authentication'
import { FavoritesContainer } from 'stores/Favorites'
import React from 'react'

export const AuthStoreProviders: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	const { userType } = useAuthState()

	if (userType === 'loggedIn') {
		return <FavoritesContainer isGlobal>{children}</FavoritesContainer>
	}
	return <>{children}</>
}
