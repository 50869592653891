import getConfig from 'next/config'
import { defaults } from 'react-sweet-state'
import { Environment } from 'types/index'

// eslint-disable-next-line sonarjs/cognitive-complexity
const getEnvFromProcess = (): Environment => {
	const {
		ROOT_DOMAIN,
		CONTENTFUL_SPACE,
		CONTENTFUL_TOKEN,
		DATADOG_CLIENT_TOKEN,
		DOCKER_VERSION,
		VERSION,
		VCS,
		BUILD_NUMBER,
		SHORT_VERSION,
		FB_ANALYTICS_TOKEN,
		GOOGLE_ANALYTICS_TOKEN,
		GOOGLE_MAPS_KEY,
		GTM_CONTAINER_ID,
		ENVIRONMENT,
		ENABLE_ANALYTICS,
		KEYCLOAK_HOST,
		KEYCLOAK_CLIENT,
		KEYCLOAK_REALM,
		API_BASE,
		DD_TRACE,
		DATADOG_APP_ID
	} = process.env
	return {
		root_domain: ROOT_DOMAIN || 'https://fiona.com',
		contentful: {
			space: CONTENTFUL_SPACE!,
			token: CONTENTFUL_TOKEN!
		},
		keycloak: {
			host: KEYCLOAK_HOST!,
			realm: KEYCLOAK_REALM!,
			clientId: KEYCLOAK_CLIENT!
		},
		version: VERSION || 'local',
		docker_version: DOCKER_VERSION || 'local',
		commit_hash: VCS || 'local',
		build_number: BUILD_NUMBER || 'local',
		short_version: SHORT_VERSION || 'local',
		facebook_token: FB_ANALYTICS_TOKEN || '',
		google_analytics_token: GOOGLE_ANALYTICS_TOKEN || '',
		gtm_container_id: GTM_CONTAINER_ID || '',
		google_maps_key: GOOGLE_MAPS_KEY || '',
		environment: ENVIRONMENT || 'local',
		enable_analytics: ENABLE_ANALYTICS === 'true' || false,
		datadog: {
			dd_trace: DD_TRACE === 'true' || false,
			client_token: DATADOG_CLIENT_TOKEN || '',
			application_id: DATADOG_APP_ID || ''
		},
		api_base: API_BASE || 'https://fiona.com'
	}
}

const actuallyGetEnv = (): Environment | null => {
	const config = getConfig()
	if (config && config.publicRuntimeConfig) {
		return config.publicRuntimeConfig
	} else {
		return getEnvFromProcess()
	}
}

export const getEnv = (): Environment => {
	return (
		actuallyGetEnv() || {
			api_base: 'https://dev.fiona.com',
			contentful: { space: '', token: '' },
			datadog: { application_id: '', dd_trace: false, client_token: '' },
			docker_version: 'local',
			commit_hash: 'local',
			build_number: 'local',
			short_version: 'local',
			google_maps_key: '',
			enable_analytics: false,
			environment: '',
			facebook_token: '',
			google_analytics_token: '',
			gtm_container_id: '',
			keycloak: { clientId: '', host: '', realm: '' },
			root_domain: ''
		}
	)
}

const isDomainSame = (domain1: string, domain2: string) => {
	try {
		return new URL(domain1).host === new URL(domain2).host
	} catch (e) {}

	return false
}

/**
 * Returns whether the authentication system is available without CORS restrictions
 * @type {boolean}
 */
export const isKeycloakOnSameDomain = isDomainSame(getEnv().keycloak.host, getEnv().root_domain)
/**
 * Returns whether this is configured to be a production environment at runtime
 * @type {boolean}
 */
export const isProduction = getEnv().environment === 'production'
/**
 * Returns whether this is configured to be a development (any environment which is not intended for customers/public, including local) environment at runtime
 * @type {boolean}
 */
export const isDevelopment = getEnv().environment != 'production'
/**
 * Returns whether this is configured to be a local environment at runtime
 * @type {boolean}
 */
export const isLocal = getEnv().environment === 'local'
/**
 * Returns whether this environment should capture analytics
 * @type {boolean}
 */
export const isAnalyticsEnabled = getEnv().enable_analytics === true
/**
 * Returns the current build version identifier
 * @type {boolean}
 */
export const VERSION = process.env.docker_version

if (isDevelopment && typeof window !== 'undefined') {
	defaults.devtools = true
}
