import { withDDReady } from 'services/AnalyticsLite/Datadog/vendor'
import { isLocal } from 'lib/envWrapper'
import { noOp } from 'lib'

const debug = isLocal ? console.info : noOp

export const addDDError = (error: any, context?: Record<string, any>) => {
	withDDReady(DD_RUM => DD_RUM.addError(error, context))
}

export const addDDTiming = (name: string) => {
	withDDReady(DD_RUM => DD_RUM.addTiming(name))
}

export const addDDGlobalContext = (key: string, value: any) => {
	withDDReady(DD_RUM => DD_RUM.addRumGlobalContext(key, value))
}

export const addDDAction = (name: string, meta: Record<string, unknown>) => {
	withDDReady(DD_RUM => DD_RUM.addAction(name, meta))
	debug(`DD Action: ${name}`, meta)
}
