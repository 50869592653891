/* eslint-disable @typescript-eslint/ban-ts-comment */
import { getEnv, isLocal } from 'lib/envWrapper'
import { isBrowser } from 'lib'
import { Callback } from 'types/index'
import type { datadogRum } from '@datadog/browser-rum-slim'

const env = getEnv()
//
// export const DD_INIT_JS = `window.DD_RUM = window.DD_RUM || {
// 					q: [],
// 					onReady: function (c) {
// 						// @ts-ignore
// 						window.DD_RUM.q.push(c)
// 					}
// 				}`
//
// export const DatadogRumScripts = (): JSX.Element => (
// 	<>
// 		<Head>
// 			<script key={'dd_init'} id={'dd_init'} type={'text/javascript'}>
// 				{DD_INIT_JS}
// 			</script>
// 		</Head>
// 		<Script src={'/inline/analytics/datadog-rum-v3.js'} strategy={'lazyOnload'} onLoad={setupInitOnReady} />
// 	</>
// )

const allowedOrigins = isLocal ? [] : ['https://fiona.com', 'https://dev.fiona.com']

export const initDatadogRUM = () => {
	if (env.enable_analytics) {
		import('@datadog/browser-rum-slim').then(dd =>
			dd.datadogRum.init({
				applicationId: env.datadog.application_id,
				clientToken: env.datadog.client_token,
				site: 'datadoghq.com',
				service: 'svc-fiona-web',
				env: env.environment,
				version: env.docker_version,
				sampleRate: 100,
				trackInteractions: true,
				allowedTracingOrigins: allowedOrigins,
				premiumSampleRate: 10
			})
		)
	}
}

export const withDDReady = (fn: Callback<typeof datadogRum>) => {
	if (isBrowser()) {
		import('@datadog/browser-rum-slim').then(dd => dd.datadogRum.onReady(() => fn(dd.datadogRum)))
	} else {
		console.error('Datadog not available as not in Browser')
	}
}
