import { css } from '@emotion/react'
import { CSSProperties } from 'react'

export const TRANSITION_DURATION = 300

interface TransitionProps {
	delay?: CSSProperties['transitionDelay']
	duration?: CSSProperties['transitionDuration']
	property?: CSSProperties['transitionProperty']
	speedCurve?: CSSProperties['transitionTimingFunction']
}

const TransitionModsFn = ({
	delay,
	duration = `${TRANSITION_DURATION}ms`,
	property = 'All',
	speedCurve = 'ease'
}: TransitionProps) =>
	css([
		css({ transitionTimingFunction: speedCurve }),
		duration && css({ transitionDuration: duration }),
		delay && css({ transitionDelay: delay }),
		property && css({ transitionProperty: property })
	])

export const TransitionMods = {
	Base: TransitionModsFn({}),
	Reset: TransitionModsFn({ duration: '0ms' })
}
