import { DefaultServerUserProfile, UserInfo } from 'services/Authentication'
import { isServer } from 'lib'
import { getIdentityFromCookie } from 'services/Authentication/Utils/Cookies'

export const getAuthUiBasedOnIDCookies = (): UserInfo => {
	if (isServer()) return DefaultServerUserProfile
	try {
		const userIdFromCookie = getIdentityFromCookie()
		if (userIdFromCookie) {
			if (userIdFromCookie.name) {
				return { profile: { displayName: userIdFromCookie.name }, userType: 'loggedIn', features: [] }
			}
			return { profile: {}, userType: userIdFromCookie.status, features: [] }
		} else {
			return { profile: {}, userType: 'anonymous', features: [] }
		}
	} catch (err) {
		return DefaultServerUserProfile
	}
}
