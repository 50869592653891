import { Action, createContainer, createHook, createStore } from 'react-sweet-state'
import type { Favorite } from 'generated/api'
import { isBrowser, noOp, Optional } from 'lib'
import { DataWrapper } from 'components/patterns/suspense/internals/types'
import {
	createDataWrapper,
	createLoadingDataWrapper,
	createStaticDataWrapper
} from 'components/patterns/suspense/internals/DataWrapper'
import { PropsWithChildren } from 'react'

interface State {
	favorites: Favorite[]
	loading?: DataWrapper<Array<Favorite>>
}

const initialState: State = {
	favorites: [],
	loading: createLoadingDataWrapper()
}
type Actions = typeof actions

const getFavoritesService = () => import('services/FionaAPI/Favorites')

const refreshFavorites =
	(): Action<State> =>
	async ({ setState }) => {
		const { getFavorites } = await getFavoritesService()
		const loadingDataWrapper = createDataWrapper(getFavorites)
		setState({ loading: loadingDataWrapper })
		const favorites = await loadingDataWrapper.onComplete()
		setState({ favorites, loading: undefined })
	}

const toggleFavorite =
	(itemId: string): Action<State> =>
	async ({ getState, setState }) => {
		const currentFavorites = getState().favorites
		if (currentFavorites.some(f => f.favoriteId === itemId)) {
			setState({ favorites: currentFavorites.filter(f => f.favoriteId !== itemId), loading: undefined })
			const { unfavoriteArticle } = await getFavoritesService()
			unfavoriteArticle(itemId).catch(console.error)
		} else {
			setState({
				favorites: [...currentFavorites, { favoriteId: itemId, favoriteType: 'article', id: '', user: '' }],
				loading: undefined
			})
			const { favoriteArticle } = await getFavoritesService()
			favoriteArticle(itemId).catch(console.error)
		}
	}

const actions = {
	refreshFavorites,
	toggleFavorite
}

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'favorites'
})

type InitialContainerProps = { initialFavorites?: Array<Favorite> }
export const FavoritesContainer = createContainer<State, Actions, PropsWithChildren<InitialContainerProps>>(Store, {
	onInit(): Action<State, InitialContainerProps> {
		return (api, { initialFavorites }) => {
			return initialFavorites
				? api.setState({ favorites: initialFavorites, loading: undefined })
				: isBrowser()
				? api.dispatch(Store.actions.refreshFavorites())
				: noOp()
		}
	}
})

type HookProps = { id: string }
export const useFavorite = createHook<State, Actions, Optional<Favorite>, HookProps>(Store, {
	selector: (state, { id }) => {
		return state.favorites.find(item => item.favoriteId === id)
	}
})

export const useFavorites = createHook<State, Actions, DataWrapper<Array<Favorite>>>(Store, {
	selector: state => {
		if (state.loading) {
			return state.loading.fork(item => item)
		}

		return createStaticDataWrapper(state.favorites)
	}
})
