import { AuthContext, AuthContextProps } from 'services/Authentication/context/context'
import { useContext } from 'react'
import Logger from 'services/Logger'
import { isServer } from 'lib'
import { assertRunningOnClient } from 'lib/renderWrappers/assertClientOnly'
import { Persistor } from 'services/Authentication/Utils'
import { getEnv } from 'lib/envWrapper'
import { clearSession } from 'services/Authentication/keycloak/session'

const env = getEnv()
/**
 * The root auth hook that abstracts away the actual context and supports SSR
 * @param requireInit indicates if this hook should initKeycloak if it has not beeen
 */
export const useAuth = (requireInit = false): AuthContextProps => {
	const authProps = useContext(AuthContext)
	if (!isServer() && requireInit && authProps.state === 'default') {
		authProps.requestInit().catch(Logger.error)
	}
	return authProps
}

/**
 * Auth hook used to return information about methods related to the state of the Auth system and it's subject (user), but not the underlying tokens
 * Use this to in most cases to access user information and to request init
 * @param requireInit indicates if this hook should initKeycloak if it has not beeen
 */
export const useAuthState = (requireInit = false) => {
	const { userInfo, state, requestInit, requestDoNotInit } = useAuth(requireInit)
	return { state, ...userInfo, requestInit, requestDoNotInit }
}

/**
 * Auth hook that returns a getter for a valid access token
 */
export const useAccessToken = () => {
	const { getToken } = useAuth(true)
	return getToken
}

export const useAuthLinks = () => {
	const { keycloak, requestInit } = useAuth()
	const login = async () => {
		await requestInit()
		const redirect = keycloak.createLoginUrl({ redirectUri: `${env.root_domain}/dashboard` })
		clearSession(encodeURIComponent(redirect))
	}
	const logout = async () => {
		await requestInit()
		const redirect = keycloak.createLogoutUrl({ redirectUri: `${env.root_domain}/` })
		clearSession(encodeURIComponent(redirect))
	}
	return { login, logout }
}

/**
 * Auth hook that returns functions relating to managing (refreshing and revoking) grants themselves
 */
export const useAuthGrants = () => {
	const props = useAuth()
	const refreshGrants = async () => {
		assertRunningOnClient()
		return props.keycloak.updateToken(-1)
	}
	const revokeGrants = async () => {
		assertRunningOnClient()
		Persistor.clearShortLivedTokens()
	}

	return { refreshGrants, revokeGrants }
}

export const useAccountFeature = (key: string): boolean => {
	const { features, profile } = useAuthState()
	const onEvenDomain = profile.email?.includes('@evenfinancial.com') || false
	const onGmail = profile.email?.includes('@gmail.com') || false
	return features.includes('all') || features.includes(key) || onEvenDomain || onGmail
}
