import { Persistor } from 'services/Authentication/Utils/Persistor'
import { buildAbsoluteUrl } from 'lib/routing/absoluteUrls'
import { clearDisplayIDCookies } from 'services/Authentication/Utils/Cookies'
import { getEnv } from 'lib/envWrapper'

const env = getEnv()
/**
 * Terminates the user's session and all access tokens.
 * @param redirect The destination to return the user
 */
export const clearSession = (redirect: string): void => {
	Persistor.resetTokens()
	clearDisplayIDCookies()

	window.location.href = `${
		env.keycloak.host
	}/realms/fiona/protocol/openid-connect/logout?redirect_uri=${buildAbsoluteUrl(redirect)}`
}
