import { AuthStates } from 'services/Authentication'
import { Nullable } from 'lib'
import dynamic from 'next/dynamic'
import React from 'react'

export const OverlayComponent = ({ state }: { state: AuthStates }): Nullable<JSX.Element> => {
	switch (state) {
		case 'authenticated':
			break
		case 'initializing':
			break
		case 'error':
			break
		case 'default':
			break
		case 'disabled':
			break
		case 'expired':
			const SessionExpiredModal = dynamic(() => import('../../../components/elements/modals/SessionExpiredModal'), {
				ssr: false
			})
			return <SessionExpiredModal />
	}
	return null
}
