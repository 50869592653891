/* eslint-disable @typescript-eslint/ban-ts-comment */
import { KeycloakLogger } from 'services/Logger'
import { KeycloakError, KeycloakInitOptions, KeycloakInstance } from 'keycloak-js'
import { defaultInitConfig } from 'services/Authentication/keycloak/configs'
import { getAuthUiBasedOnIDCookies, isFirstLoad, Persistor, storeIdentity } from 'services/Authentication/Utils'
import { AuthStates, UserInfo } from 'services/Authentication/types'
import { Experiments } from 'services/ExperimentService/ExperimentState'
import { Callback } from 'types/index'
import { createAnonUser, initAnonUserSSOSession } from 'services/Authentication/keycloak/AnonymousSessions'
import { isKeycloakOnSameDomain } from 'lib/envWrapper'
import { RefreshTokenError } from '../Utils'
import { addDDError, addDDGlobalContext, addDDTiming } from 'services/AnalyticsLite/Datadog/events'
import { ga4Client } from 'services/AnalyticsLite/GA4/utils/client'

const runAsAnonymous = async () => {
	KeycloakLogger.info('Creating anon user')
	const anonGrants = await createAnonUser()
	const tokens = {
		token: anonGrants.access_token,
		idToken: anonGrants['id_token'],
		refreshToken: anonGrants['refresh_token']
	}
	if (isKeycloakOnSameDomain) await initAnonUserSSOSession(anonGrants.access_token!)
	Persistor.setTokens(tokens)
	return tokens
}

export const initializeKeycloak = async (
	keycloak: KeycloakInstance,
	setState: Callback<AuthStates>,
	setProfile: Callback<UserInfo>,
	initOptions: Partial<KeycloakInitOptions> = {}
	// eslint-disable-next-line sonarjs/cognitive-complexity
): Promise<boolean> => {
	if (keycloak.authenticated) {
		return Promise.resolve(true)
	}
	setState('initializing')
	// Attach Keycloak listeners
	keycloak.onReady = (authenticated?: boolean) => {
		if (authenticated) {
			KeycloakLogger.debug('Keycloak Onready Fired')
			keycloak.updateToken(100).then(refreshed => {
				if (refreshed) {
					KeycloakLogger.debug('Keycloak token refresh was required ')
					setState('authenticated')
					checkAuthStatus(keycloak, setProfile).catch(addDDError)
				} else {
					console.log('Keycloak token is still valid')
				}
			})
		} else if (authenticated === false) {
			KeycloakLogger.info('User is Not Authenticated')
		} else {
			addDDGlobalContext('auth', {
				success: false
			})
			KeycloakLogger.debug('All hope is lost')
		}
	}
	keycloak.onAuthSuccess = () => {
		KeycloakLogger.debug('Auth success callback')
		addDDTiming('auth_success')
		Persistor.setTokens({
			token: keycloak.token,
			idToken: keycloak.idToken,
			refreshToken: keycloak.refreshToken
		})
		setState('authenticated')
		checkAuthStatus(keycloak, setProfile).catch(addDDError)
	}
	keycloak.onAuthError = (errorData: KeycloakError) => {
		KeycloakLogger.error('Auth Error Event Callback, setting state to default', errorData)
		setState('default')
	}
	keycloak.onTokenExpired = () => {
		setState('initializing')
		keycloak
			.updateToken(-1)
			.then(refreshed => KeycloakLogger.info('expired toke refreshed ' + refreshed))
			.catch(err => {
				addDDError(err, { location: 'keycloak.updateToken after expiry' })
				KeycloakLogger.error('Auth token expired Event Callback, but could not update token, setting to default')
				setState('default')
			})
	}
	keycloak.onAuthRefreshSuccess = () => {
		KeycloakLogger.debug('Auth Refresh Success')
		setState('authenticated')
		Persistor.setTokens({
			token: keycloak.token,
			idToken: keycloak.idToken,
			refreshToken: keycloak.refreshToken
		})
	}
	keycloak.onAuthRefreshError = () => {
		KeycloakLogger.error('onAuthRefreshError called, resetting tokens')
		Persistor.resetTokens()
		const lastKnownState = getAuthUiBasedOnIDCookies()
		addDDError(new RefreshTokenError(lastKnownState.userType))
		if (lastKnownState.userType === 'loggedIn') {
			setState('expired')
		} else {
			setState('default')
		}
	}
	keycloak.onAuthLogout = () => {
		KeycloakLogger.info('Logout Event fired  setting state to default')
		Persistor.resetTokens()
		setState('default')
	}

	if (!isFirstLoad()) {
		const persisted = Persistor.getTokens()
		const initSuccess = await keycloak.init({ ...defaultInitConfig, ...persisted, timeSkew: 0, ...initOptions })
		if (initSuccess) {
			return true
		}
		KeycloakLogger.log('Resetting tokens because they didnt validate')
		Persistor.resetTokens()
	}
	const anonTokens = await runAsAnonymous()
	return keycloak.init({ ...defaultInitConfig, ...anonTokens, timeSkew: 0, ...initOptions })
}

const getFeaturesFromIDToken = (keycloak: KeycloakInstance): Array<string> => {
	const array = []
	if (keycloak.idTokenParsed) {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const values = keycloak.idTokenParsed['features'] as string[]
		if (values && values.length > 0) {
			array.push(...values)
		}
	}
	return array
}

const getUserDaysSinceSignupFromIDToken = (idToken?: Record<string, any>) => {
	if (idToken) {
		const createdDate = idToken.createdDate
		if (createdDate && typeof createdDate === 'number') {
			const diffDateMs = Date.now() - createdDate
			return diffDateMs / (1000 * 60 * 60 * 24)
		}
	}
	return undefined
}

const checkAuthStatus = async (keycloak: KeycloakInstance, setProfile: Callback<UserInfo>): Promise<void> => {
	if (keycloak.authenticated) {
		if (keycloak.hasRealmRole('standard')) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const preferredName = keycloak.tokenParsed.given_name
			KeycloakLogger.info('Standard User')
			Experiments.updateContextFromKeycloak(keycloak)
			storeIdentity('loggedIn', preferredName, keycloak.subject)
			const authContext = {
				userId: keycloak.subject,
				role: 'standard',
				success: true
			}
			addDDGlobalContext('auth', authContext)
			keycloak.subject && ga4Client.userClient.setUserId(keycloak.subject)
			ga4Client.userClient.setUserProperties({ user_type: 'loggedIn' })
			// @ts-ignore
			const email = keycloak.tokenParsed?.email
			setProfile({
				profile: {
					...keycloak.profile,
					email: email,
					displayName: preferredName,
					role: 'standard',
					daysSinceSignup: getUserDaysSinceSignupFromIDToken(keycloak?.idTokenParsed)
				},
				userType: 'loggedIn',
				id: keycloak.subject,
				features: getFeaturesFromIDToken(keycloak)
			})
		} else {
			KeycloakLogger.info('Guest User')
			const authContext = {
				userId: keycloak.subject,
				role: 'guest',
				success: true
			}
			addDDGlobalContext('auth', authContext)
			Experiments.updateContextFromKeycloak(keycloak)
			ga4Client.userClient.setUserProperties({ user_type: 'anonymous' })
			storeIdentity('anonymous', undefined, keycloak.subject)
			setProfile({ profile: { role: 'guest' }, userType: 'anonymous', features: [] })
		}
	}
}
