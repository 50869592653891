import { isBrowser, Nullable } from 'lib'

const serialize = (value: string | Object) => (typeof value === 'string' ? value : JSON.stringify(value))
const deserialize = (value: string) => JSON.parse(value) ?? value

const MockStorage: Storage = {
	key(): string | null {
		return null
	},
	length: 0,
	removeItem(): void {
		// do nothing
	},
	clear(): void {
		// do nothing
	},
	getItem(): string | null {
		return null
	},
	setItem(): void {
		// do nothing
	}
}

const localStorage = isBrowser() ? window.localStorage : MockStorage
const sessionStorage = isBrowser() ? window.sessionStorage : MockStorage

const storageFactory = (storage?: Storage) => ({
	set: (key: string, value: string | Object) => {
		storage?.setItem(key, serialize(value))
	},
	get: (key: string): Nullable<string | Record<string, unknown>> => {
		const value = storage?.getItem(key)
		return value ? deserialize(value) : value
	}
})

export const BrowserStorage = {
	Local: storageFactory(localStorage),
	Session: storageFactory(sessionStorage)
}
