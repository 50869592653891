import { useEffect } from 'react'

export const useObserver = (callback: PerformanceObserverCallback, enabled = true) => {
	useEffect(() => {
		if (enabled) {
			let po: PerformanceObserver
			if (typeof window != 'undefined') {
				try {
					po = new window.PerformanceObserver(callback)
					po.observe({ entryTypes: ['first-input'], buffered: false })
				} catch (e) {}
			}
			return (): void => {
				try {
					if (po) {
						po.disconnect()
					}
				} catch (err) {}
			}
		}
	}, [enabled, callback])
}
