import { KeycloakInstance } from 'keycloak-js'
import { KeycloakTokens } from '../Utils/Persistor'

const noops = {
	string: () => '',
	boolean: () => true,
	promise: () => new Promise<boolean>(() => true)
}

// KeycloakStub singleton
let keycloakMockInstance: KeycloakInstance

// this is a fake Keycloak instance we use to initialize Keycloak on the server.
// This gets over-written as soon as Keycloak is initialized on the client.
export const getKeycloakMock = (): KeycloakInstance => {
	const kcTokens: KeycloakTokens = {}
	if (!keycloakMockInstance) {
		keycloakMockInstance = {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			login: () => Promise.resolve(),
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			logout: () => Promise.resolve(),
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			register: () => Promise.resolve(),
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			accountManagement: () => Promise.resolve(),
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			init: () => Promise.resolve(),
			createLoginUrl: noops.string,
			createLogoutUrl: noops.string,
			createAccountUrl: noops.string,
			createRegisterUrl: noops.string,
			isTokenExpired: noops.boolean,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			updateToken: () => Promise.resolve(),
			clearToken: noops.string,
			hasRealmRole: noops.boolean,
			hasResourceRole: noops.boolean,
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			loadUserProfile: () => Promise.resolve(),
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			loadUserInfo: () => Promise.resolve(),
			authenticated: !!kcTokens.token,
			idToken: 'mock',
			token: kcTokens.token,
			refreshToken: kcTokens.refreshToken
		}
	}
	return keycloakMockInstance
}
