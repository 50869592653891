import {
	track,
	trackEvent,
	trackEventAsync,
	trackPageView,
	trackPurchase
} from 'services/AnalyticsLite/GA4/utils/standardEvents'
import { setUserId, setUserProperties } from 'services/AnalyticsLite/GA4/utils/userProperties'
import { isBrowser } from 'lib'
import { GtagParams } from 'services/AnalyticsLite/context'

const eventsClient = {
	trackEvent,
	track,
	trackPageView,
	trackEventAsync,
	trackPurchase
}

const userClient = {
	setUserId,
	setUserProperties
}

type EventsClient = typeof eventsClient
type UserClient = typeof userClient

export interface GA4Clients {
	eventsClient: EventsClient
	userClient: UserClient
}

const defaultGA4Client: GA4Clients = {
	userClient,
	eventsClient
}

export interface Ga4Event {
	name: string
	params?: GtagParams
}

export const mockGA4Client: GA4Clients = {
	userClient: {
		setUserId(id) {
			console.log('setUserId called: ' + id)
		},
		setUserProperties(props) {
			console.log(`setUserProperties called: ${JSON.stringify(props)}`)
		}
	},
	eventsClient: {
		trackPurchase(referenceId: string) {
			console.log('trackPurchase called: ' + referenceId)
		},
		trackEvent(name) {
			console.log('trackEvent called: ' + name)
		},
		track(event: Ga4Event): void {
			console.log('track called: ' + event.name)
		},
		trackEventAsync(): Promise<void> {
			return Promise.resolve(undefined)
		},
		trackPageView() {
			console.log('trackPageView called')
		}
	}
}

export const ga4Client = isBrowser() ? defaultGA4Client : mockGA4Client
