import { COLORS } from 'parameters/index'
import { css } from '@emotion/react'
import { CONTENTFUL_IMAGE } from 'lib/css/magicClasses'
import { SpacePropertiesStyle, SpaceSettingStyle } from 'components/styles/definitions/size/typographyScale'
import { TransitionMods } from 'components/styles'

export const normalize = css`
	/* nunito-regular - latin */
	@font-face {
		font-family: 'Nunito';
		font-style: normal;
		font-display: swap;
		font-weight: 400;
		unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
			U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
		src: /*Local is best*/
			local('Nunito Regular'),
			local('Nunito-Regular'),
			url('/inline/fonts/nunito/Nunito-Regular.woff2') format('woff2'),
			/* Super Modern Browsers */ url('/inline/fonts/nunito/nunito-v12-latin-regular.woff2') format('woff2'),
			/* Modern Browsers */ url('/inline/fonts/nunito/nunito-v12-latin-regular.woff') format('woff'),
			/* Safari, Android, iOS */ url('/inline/fonts/nunito/nunito-v12-latin-regular.ttf') format('truetype'),
			/* Legacy - probably dead */ url('/inline/fonts/nunito/nunito-v12-latin-regular.eot?#iefix')
				format('embedded-opentype'),
			/* Legacy - probably dead */ url('/inline/fonts/nunito/nunito-v12-latin-regular.svg#Nunito') format('svg');
	}
	/* nunito-700 - latin */
	@font-face {
		font-family: 'Nunito';
		font-style: normal;
		font-display: swap;
		font-weight: 700;
		src:
			local('Nunito Bold'),
			local('Nunito-Bold'),
			url('/inline/fonts/nunito/Nunito-Bold.woff2') format('woff2'),
			/* Super Modern Browsers */ url('/inline/fonts/nunito/nunito-v12-latin-700.woff2') format('woff2'),
			/*  Modern Browsers */ url('/inline/fonts/nunito/nunito-v12-latin-700.woff') format('woff'),
			/* Safari, Android, iOS */ url('/inline/fonts/nunito/nunito-v12-latin-700.ttf') format('truetype'),
			/* Legacy - probably dead  */ url('/inline/fonts/nunito/nunito-v12-latin-700.eot?#iefix')
				format('embedded-opentype'),
			/* Legacy - probably dead */ url('/inline/fonts/nunito/nunito-v12-latin-700.svg#Nunito') format('svg');
	}

	body {
		color: ${COLORS.JET_BLACK};
		${SpaceSettingStyle}
		${SpacePropertiesStyle}
	}

	* {
		font-family: 'Nunito', Helvetica, sans-serif;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		line-height: 1.07;
	}

	h1 {
		font-size: 2.5rem;
		@media (max-width: 768px) {
			font-size: 1.875rem;
		}
	}

	h2 {
		font-size: 1.875rem;
		line-height: 1.4;
		@media only screen and (max-width: 768px) {
			font-size: 1.5rem;
		}
	}

	h3 {
		font-size: 1.375rem;
		@media only screen and (max-width: 768px) {
			font-size: 1.25rem;
		}
	}

	h4 {
		font-size: 1.25rem;

		@media only screen and (max-width: 768px) {
			font-size: 1.125rem;
		}
	}

	h5 {
		font-size: 1rem;
	}

	p {
		line-height: 1.63;

		strong {
			font-weight: 700;
		}
	}

	a {
		${TransitionMods.Base};
	}

	fieldset {
		border: none;
	}

	legend {
		display: none;
	}

	button {
		letter-spacing: 0;
	}

	.${CONTENTFUL_IMAGE} {
		transition: filter 0.3s;
	}
`
