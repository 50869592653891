import Cookie, { CookieAttributes } from 'js-cookie'
import { isServer } from 'lib'

export const setCookie = (name: string, val: string, attr?: CookieAttributes) => {
	Cookie.set(name, val, attr)
	return val
}

export const getCookie = (name: string, cookies: { [x: string]: string } = {}) =>
	isServer() ? cookies[name] : Cookie.get(name)

export const removeCookie = (name: string) => {
	Cookie.remove(name)
}
