export type DeepPartial<T> = {
	[P in keyof T]?: DeepPartial<T[P]>
}

export const deepUpdate = <T>(a: T, b: DeepPartial<T>) => {
	const clone = JSON.parse(JSON.stringify(a)) as T
	return Object.entries(b).reduce((o, [k, v]) => {
		// Theres no good way to make typescript happy here. Edit at your own peril.
		// @ts-ignore
		o[k] =
			v && typeof v === 'object'
				? // @ts-ignore
				  deepUpdate((o[k] = o[k] || (Array.isArray(v) ? [] : {})), v)
				: v
		return o
	}, clone)
}
