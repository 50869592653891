import { ACCESS_TOKEN_COOKIE, ID_TOKEN_COOKIE, REFRESH_TOKEN_LOCALSTORAGE } from './Cookies'

export interface KeycloakTokens {
	token?: string
	idToken?: string
	refreshToken?: string
}

export class Persistor {
	static getTokens(): KeycloakTokens {
		const set: { [x: string]: any } =
			typeof localStorage !== 'undefined'
				? {
						token: localStorage.getItem(ACCESS_TOKEN_COOKIE),
						refreshToken: localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE),
						idToken: localStorage.getItem(ID_TOKEN_COOKIE)
				  }
				: {}
		Object.keys(set).forEach(key => set[key] == null && delete set[key])
		return set
	}

	static resetTokens(): void {
		if (typeof localStorage !== 'undefined') {
			localStorage.removeItem(ACCESS_TOKEN_COOKIE)
			localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE)
			localStorage.removeItem(ID_TOKEN_COOKIE)
		}
	}

	static clearShortLivedTokens(): void {
		if (typeof localStorage !== 'undefined') {
			localStorage.removeItem(ACCESS_TOKEN_COOKIE)
			localStorage.removeItem(ID_TOKEN_COOKIE)
		}
	}

	static setTokens({ token, idToken, refreshToken }: KeycloakTokens): void {
		if (typeof localStorage !== 'undefined') {
			if (token) localStorage.setItem(ACCESS_TOKEN_COOKIE, token)
			if (refreshToken) localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE, refreshToken)
			if (idToken) localStorage.setItem(ID_TOKEN_COOKIE, idToken)
		}
	}
}
