import { isBrowser } from '../index'

type RequestIdleCallbackOptions = { timeout: number }
type RequestIdleCallbackDeadline = {
	readonly didTimeout: boolean
	timeRemaining: () => number
}

export interface IdleCallbackWindow extends Window {
	requestIdleCallback: (
		callback: (deadline: RequestIdleCallbackDeadline) => void,
		opts?: RequestIdleCallbackOptions
	) => any
	cancelIdleCallback: (handle: any) => void
}

declare const window: IdleCallbackWindow

export const requestIdleCallback = (callback: () => void): any => {
	if (isBrowser()) {
		if ('requestIdleCallback' in window) {
			return window.requestIdleCallback(callback)
		} else {
			setTimeout(callback, 5)
			return 'unsupported'
		}
	} else {
		callback()
		return 'unsupported'
	}
}
