export const wait = <T>(ms: number, value?: T): Promise<T> => {
	return new Promise<T>(resolve => setTimeout(resolve, ms, value))
}

/**
 * Waits for the specified number of ms, then attempts the async function
 * @param {number} ms
 * @param {() => Promise<T>} fn
 * @return {Promise<T>}
 */
export const delayedPromise = <T>(ms: number, fn: () => Promise<T>): Promise<T> => {
	return new Promise<T>((resolve, reject) =>
		setTimeout(() => {
			fn().then(resolve).catch(reject)
		}, ms)
	)
}
