import { KeycloakInstance } from 'keycloak-js'
import { AuthAlerts } from 'services/Authentication'
import { KeycloakLogger } from 'services/Logger'
import React from 'react'
import { getKeycloakMock } from 'services/Authentication/keycloak/Mock'
import { AuthStates, UserInfo } from 'services/Authentication/types'

export type AuthContextProps = {
	keycloak: KeycloakInstance
	getToken: () => string
	userInfo: UserInfo
	reportError: (alert: AuthAlerts) => Promise<void>
	requestInit: (force?: boolean) => Promise<void>
	requestDoNotInit: () => void
	state: AuthStates
}

export const AuthContext = React.createContext<AuthContextProps>({
	state: 'default',
	keycloak: getKeycloakMock(),
	requestDoNotInit: () => {
		// do nothing
	},
	getToken: () => '',
	userInfo: { profile: {}, userType: 'unknown', features: [] },
	reportError: alert => Promise.resolve(KeycloakLogger.error(alert)),
	requestInit: () => Promise.resolve()
})
