import type { KeycloakInstance } from 'keycloak-js'
import { isServer } from 'lib'
import { useAuth } from 'services/Authentication/context/hooks'
import { getKeycloakMock } from 'services/Authentication/keycloak/Mock'
import { useRouter } from 'next/router'

/**
 * Legacy hook for exisitng uses. Rely on {@link useAuthState} instead
 */
export const useKeycloak = (): [KeycloakInstance, boolean, boolean] => {
	const { keycloak, state } = useAuth()
	return [keycloak || getKeycloakMock(), state === 'authenticated', isServer()]
}

const hasOauthCode = new RegExp(/#(.*)code/)

export const useAuthConfig = () => {
	const router = useRouter()
	const hasOauthStateAnchor = hasOauthCode.test(router.asPath)
	const onImptPage =
		['/dashboard', '/account', '/search/history', '/learn/reading-list'].includes(router.pathname) ||
		router.pathname.includes('/apply')
	const shouldInit = onImptPage
	return { shouldInit, hasOauthStateAnchor }
}
