import { getEnv } from 'lib/envWrapper'
import { KeycloakLogger } from 'services/Logger'
import { addDDTiming } from 'services/AnalyticsLite/Datadog/events'

export const initAnonUserSSOSession = async (token: string): Promise<void> => {
	const url = `${getEnv().keycloak.host}/realms/fiona/anonymous-session`
	const headers = new Headers()
	headers.set('Authorization', `Bearer ${token}`)
	try {
		await fetch(url, { headers, cache: 'no-store' })
	} catch (err) {
		KeycloakLogger.error('Unable to get SSO Session', err)
	}
}

export const createAnonUser = async (): Promise<Grants> => {
	addDDTiming('create_anon_user')
	const { getUserClient } = await import('services/FionaAPI/clients')
	return getUserClient()
		.createAnonymous({})
		.then(r => r as Grants)
}

interface Grants {
	access_token: string
	expires_in: number
	refresh_expires_in: number
	refresh_token: string
	token_type: string
	id_token: string
	'not-before-policy': number
	session_state: string
	scope: string
}
