import { Theme } from '@emotion/react'
import { CardTheme, IconTheme, RibbonTheme, TextTheme } from './themes'
import { COLORS } from 'parameters/index'

// TODO: Update to new Theme Definition
declare module '@emotion/react' {
	export interface Theme {
		icon: IconTheme
		card: CardTheme
		ribbon: RibbonTheme
		text: TextTheme
	}
}

// TODO: Update to new Theme Definition
export const BaseTheme: Theme = {
	card: { background: { type: 'color', value: '#fff' }, border: '' },
	icon: { primaryFill: COLORS.KSU_PURPLE, secondaryFill: COLORS.KSU_PURPLE, backgroundColor: 'transparent' },
	ribbon: { background: { type: 'preset', value: 'grey' }, border: '' },
	text: { color: COLORS.JET_BLACK }
}
