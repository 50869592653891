import { GtagParams } from '../../context'
import { isBrowser, wait } from 'lib'
import { Ga4Event } from 'services/AnalyticsLite/GA4/utils/client'

const gtag = isBrowser() && window.gtag ? window.gtag : console.log

export const trackEvent = (name: string, params: GtagParams = {}) => {
	if (isBrowser()) {
		gtag('event', name, params)
	}
}

export const track = (event: Ga4Event) => {
	if (isBrowser()) {
		gtag('event', event.name, event.params)
	}
}

export const trackEventAsync = async (name: string, params: GtagParams = {}, timeout = 750): Promise<void> => {
	const callback = new Promise(resolve => {
		gtag('event', name, { ...params, event_callback: resolve })
	})
	const timeoutPromise = wait(timeout, null)
	await Promise.race([callback, timeoutPromise])
}

export const trackPageView = () => {
	gtag('event', 'page_view')
}

export const trackPurchase = (referenceId: string, label: string, value: number) => {
	gtag('event', 'purchase', {
		transaction_id: referenceId,
		affiliation: label,
		value: value,
		currency: 'USD'
	})
}
