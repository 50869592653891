import { AuthContext, AuthContextProps } from './context'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AuthAlerts, DefaultServerUserProfile } from 'services/Authentication'
import { Callback } from 'types/index'
import { initializeKeycloak } from 'services/Authentication/keycloak/keycloakUtils'
import { useObserver } from 'lib/hooks/useObserver'
import { createKeycloakObserver } from 'services/Authentication/Utils/AlertKeycloakObserver'
import { TokenUndefined } from '../Utils/errors'
import { createDefaultKeycloak } from 'services/Authentication/keycloak/KeycloakFactory'
import { AuthStates, UserInfo } from 'services/Authentication/types'
import { isServer } from 'lib'
import { KeycloakLogger } from 'services/Logger'
import { OverlayComponent } from '../components/OverlayComponent'
import { ExternalAuthMounter } from 'services/Authentication/components/ExternalAuthMounter'
import { UnauthorizedError } from 'services/FionaAPI/templates/errors'
import { Persistor } from 'services/Authentication/Utils/Persistor'
import AnalyticsInstance from 'services/AnalyticsLite/AnalyticsService'
import { setUserId } from 'services/AnalyticsLite/GA4/utils/userProperties'
import { requestIdleCallback } from 'lib/types/window'
import { useAuthConfig } from 'services/Authentication/context/useKeycloak'
import { useLazyRef } from 'lib/hooks/useLazyRef'
import { clearDisplayIDCookies } from 'services/Authentication/Utils/Cookies'
import { getAuthUiBasedOnIDCookies } from 'services/Authentication/Utils/utils'
import { addDDError } from '../../AnalyticsLite/Datadog/events'

interface Props {
	initImmediately?: boolean
	allowAnonUserCreation?: boolean
}

export const AuthProvider: React.FC<React.PropsWithChildren<Props>> = ({ children }): JSX.Element => {
	// These properties are more like instance variables than render props,
	// and so use refs over state. Updating these will not cause a re-render
	const keycloak = useLazyRef(createDefaultKeycloak)
	const pendingRequests = useRef<Array<Callback<void>>>([])
	const state = useRef<AuthStates>('default')
	const [publicState, setPublicState] = useState<AuthStates>('default')
	const { shouldInit } = useAuthConfig()
	// We keep profile in state because it is the UI state of the Auth system
	const [profile, setProfile] = useState<UserInfo>(getAuthUiBasedOnIDCookies)

	useObserver(
		createKeycloakObserver(() => state.current === 'default'),
		!shouldInit
	)

	useEffect(() => {
		if (profile.userType === 'loggedIn' && profile.id) {
			setUserId(profile.id)
			AnalyticsInstance.identify(profile.id, { userType: profile.userType })
		}
	}, [profile])

	// We still need a way to process the state change events, so do so via this switch
	const setState: Callback<AuthStates> = newState => {
		if (newState !== state.current) {
			KeycloakLogger.info(`changing auth state from ${state.current} to ${newState}`)
			state.current = newState
			switch (newState) {
				case 'authenticated':
					KeycloakLogger.debug('Auth State Set ')
					break
				case 'initializing':
					break
				case 'error':
					KeycloakLogger.error('Setting state to error')
					Persistor.resetTokens()
					break
				case 'default':
					break
				case 'disabled':
					KeycloakLogger.error('Setting state to disabled')
					break
				case 'expired':
					setProfile({ profile: {}, userType: 'loggedOut', features: [] })
					clearDisplayIDCookies()
					break
			}
		}
	}

	/**
	 * Core init funciton called by hooks, supports client agnostic callbacks so no polling
	 */
	const requestInit = useCallback(
		async (force = false): Promise<void> => {
			KeycloakLogger.info(`${force ? 'forced' : ''} init requested, current state: ${state.current}`)
			switch (state.current) {
				case 'authenticated':
					if (!force) return Promise.resolve()
				//Intentional fallthrough to init if force is true
				case 'expired':
				case 'error':
				case 'default':
					if (!keycloak.current) return
					state.current = 'initializing'
					const pendingPromise = new Promise<void>(resolve => pendingRequests.current.push(resolve))
					const callback = () => {
						initializeKeycloak(keycloak.current, setState, setProfile)
							.then(() => {
								KeycloakLogger.debug('Keycloak Ready, resolving pending clients')
								pendingRequests.current.forEach(resolve => resolve())
								pendingRequests.current = []
								setPublicState(state.current)
							})
							.catch(e => {
								addDDError(e)
								KeycloakLogger.error(e)
								setState('error')
								KeycloakLogger.debug('Initializing Immediately On Error')
								requestInit().then(() => KeycloakLogger.debug(' Immediate Initialization On Error Complete'))
							})
					}
					requestIdleCallback(callback)
					return pendingPromise
				case 'initializing':
					KeycloakLogger.debug('waiting in line for init')
					return new Promise<void>(resolve => pendingRequests.current.push(resolve))
				case 'disabled':
					KeycloakLogger.info('Init request ignored due to disabled or unrecoverable error')
					return
			}
		},
		[keycloak]
	)

	const getToken = (): string => {
		const kc = keycloak.current
		if (kc && kc.authenticated && kc.token) return kc.token
		throw new TokenUndefined()
	}

	const requestDoNotInit = () => (state.current = 'disabled')

	const reportError = async (alert: AuthAlerts) => {
		switch (alert) {
			case AuthAlerts.API_401:
				await requestInit()
				break
			case AuthAlerts.API_403:
				throw new UnauthorizedError()
			case AuthAlerts.REFRESH_FAIL:
				break
		}
	}

	const props: AuthContextProps = {
		keycloak: keycloak.current,
		state: publicState,
		requestDoNotInit,
		reportError,
		requestInit,
		userInfo: isServer() ? DefaultServerUserProfile : profile,
		getToken
	}

	/**
	 * This likely also handled initializing if the state was reset due to errors
	 */
	useEffect(() => {
		if (shouldInit && state.current === 'default') {
			KeycloakLogger.debug('Initializing Immediately')
			requestInit().then(() => KeycloakLogger.debug(' Immediate Initialization Complete'))
		}
	}, [requestInit, shouldInit])

	return (
		<AuthContext.Provider value={props}>
			<ExternalAuthMounter {...props} />
			<OverlayComponent state={state.current} />
			{children}
		</AuthContext.Provider>
	)
}
