import { asyncNoOp } from 'lib'
import { ga4Client } from 'services/AnalyticsLite/GA4/utils/client'

/**
 * @deprecated - prefer the useAnalytics or direct client. This file exists for legacy implementations
 */
const AnalyticsService = {
	async track(eventName: string, payload: any, callback?: ((...params: any[]) => any) | undefined): Promise<any> {
		return ga4Client.eventsClient.trackEventAsync(eventName, payload).then(() => callback && callback())
	},
	async identify(userId: string, traits: any): Promise<any> {
		ga4Client.userClient.setUserId(userId)
		ga4Client.userClient.setUserProperties(traits)
	},
	page: asyncNoOp
}

export default AnalyticsService
