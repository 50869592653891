import type { OfferWrapper } from 'generated/api'
import Logger from '../../Logger'
import React, { useEffect } from 'react'
import { requestIdleCallback } from 'lib/types/window'
import { datadogRum } from '@datadog/browser-rum-slim'

export const GoogleAdsClient = {
	init: () => {
		try {
			requestIdleCallback(() => {
				window.gtag('config', 'AW-596592825')
			})
		} catch (err) {
			Logger.error(err)
		}
	},
	offerClick: (offer: OfferWrapper): Promise<void> => {
		return new Promise<void>(resolve => {
			try {
				window.gtag('event', 'conversion', {
					send_to: 'AW-596592825/ew_JCKjUxNADELmRvZwC',
					value: (offer.recommendation.classicRecScore || 0) / 100,
					currency: 'USD',
					transaction_id: offer.key,
					event_callback: () => {
						resolve()
					}
				})
			} catch (err) {
				datadogRum.addError(err)
				resolve()
			}
		})
	}
}

export const GoogleAdsInit: React.FC = () => {
	useEffect(() => {
		try {
			GoogleAdsClient.init()
		} catch (err) {
			datadogRum.addError(err)
		}
	}, [])
	return null
}
