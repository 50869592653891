/* eslint-disable sonarjs/no-duplicate-string,sonarjs/cognitive-complexity */
import type { Context } from '@datadog/browser-core'
import type { Logger } from '@datadog/browser-logs'
import { getEnv } from 'lib/envWrapper'
import { initDDLogger } from 'services/Logger/DD_Logger'
import { addDDError } from 'services/AnalyticsLite/Datadog/events'

const env = getEnv()
const isLocal = env.environment === 'local'
const isProduction = env.environment === 'production'
const isServer = typeof window === 'undefined'

interface HandlerParams {
	stringValue?: string
	contextValue?: { [x: string]: string }
	error?: Error
}

type HandlerFn = (p: HandlerParams) => void

interface Handler {
	name: string
	log: HandlerFn
	info: HandlerFn
	debug: HandlerFn
	warn: HandlerFn
	error: HandlerFn
}

type TranslatorConsole = (fn: typeof console.log) => HandlerFn

const ConsoleHandler = (): Handler => {
	const translate: TranslatorConsole =
		fn =>
		(params): void => {
			if (isServer) {
				fn(JSON.stringify({ log: params.stringValue, ...params }))
			} else if (!params.contextValue) {
				fn(params.stringValue || 'Unknown Error')
			} else {
				fn(...[params.stringValue, params.contextValue, params.error].filter(x => x))
			}
		}
	return {
		name: 'console ' + Math.random(),
		log: translate(console.log),
		info: translate(console.log),
		debug: translate(console.debug),
		warn: translate(console.warn),
		error: translate(console.error)
	}
}
type Translator = (fn: (message: string, context?: Context) => void) => HandlerFn

const DatadogHandler = (name: string): Handler => {
	const translate: Translator = fn => (params: HandlerParams) => {
		fn(params.stringValue || 'Unknown Error', { ...(params?.contextValue || {}) })
	}
	const translateError: Translator = fn => params => {
		addDDError(params.error, { ...params.contextValue, message: params.stringValue })
		if (params.error) {
			const errorContext: Context = {
				name: params.error.name,
				stack: params.error.stack,
				message: params.error.message
			}
			fn(params.error?.name ? `${params.error.name} ${params.error.message}` : 'Unknown Error', {
				...(params?.contextValue || {}),
				...errorContext
			})
		} else {
			fn(params.stringValue || 'Unknown Error', { ...(params?.contextValue || {}) })
		}
	}

	let logger: Handler | undefined
	const build = (logger: Logger) => {
		return {
			name: 'DD Logger ' + name,
			log: translate(logger.info.bind(logger)),
			debug: translate(logger.debug.bind(logger)),
			info: translate(logger.info.bind(logger)),
			warn: translate(logger.warn.bind(logger)),
			error: translateError(logger.error.bind(logger))
		}
	}
	const buffer: HandlerParams[] = []
	initDDLogger().then(() => {
		import('@datadog/browser-logs').then(({ datadogLogs }) => {
			const ddLogger = datadogLogs.createLogger(name)
			logger = build(ddLogger)
			buffer.forEach(x => logger?.info(x))
		})
	})

	const availableLogger = (level: keyof Omit<Handler, 'name'>) => (params: HandlerParams) => {
		try {
			if (logger) {
				logger[level](params)
			} else {
				buffer.push(params)
			}
		} catch (err) {
			buffer.push(params)
		}
	}

	return {
		name: 'Outer DD ' + name,
		log: availableLogger('info'),
		debug: availableLogger('debug'),
		info: availableLogger('info'),
		warn: availableLogger('warn'),
		error: availableLogger('error')
	}
}

type LoggerFunction = (...args: unknown[]) => void

const LevelLogFactory =
	(level: string, name: string, transports: HandlerFn[]): LoggerFunction =>
	(...args: unknown[]) => {
		const messageArray = [`[${name}][${level}]`]
		const context: { [x: string]: string } = {}
		let error: Error | undefined = undefined
		args.forEach(value => {
			if (value) {
				if (typeof value === 'string') {
					messageArray.push(value)
				} else if (value instanceof Error) {
					error = value
				} else if (typeof value === 'object') {
					const object = JSON.parse(JSON.stringify(value))
					if (object.name && object.message) {
						messageArray.push(`${object?.name}: ${object?.message}, ${JSON.stringify({ ...object })}`)
					} else {
						messageArray.push(JSON.stringify(value))
					}
					if (object.stack) {
						context.stack = typeof object.stack === 'string' ? object.stack : JSON.stringify(object.stack)
					}
				} else {
					messageArray.push(JSON.stringify(value))
				}
			} else {
				if (value === null) {
					messageArray.push('Null Error Occurred ??')
				} else if (value === undefined) {
					messageArray.push('Undefined Error Occurred ??')
				}
				try {
					messageArray.push(`Unknown Error Occurred of type: ${typeof value}`)
				} catch (e) {
					messageArray.push('Error thrown while trying to log detail about the error objects type (AHH!)')
				}
			}
		})
		const message = messageArray.join(' ')
		transports.forEach(fn => fn({ stringValue: message, contextValue: context, error }))
	}

interface NamedLoggerInstance {
	name: string
	info: LoggerFunction
	debug: LoggerFunction
	log: LoggerFunction
	warn: LoggerFunction
	error: LoggerFunction
}

const NamedLoggerFactory = (name: string): NamedLoggerInstance => {
	let baseHandlers: Handler[] = []
	if (isServer || isLocal) {
		baseHandlers = [ConsoleHandler()]
	} else {
		baseHandlers = [DatadogHandler(name)]
		if (!isProduction) {
			baseHandlers.push(ConsoleHandler())
		}
	}
	return {
		name,
		log: LevelLogFactory(
			'log',
			name,
			baseHandlers.map(x => x.info)
		),
		warn: LevelLogFactory(
			'warn',
			name,
			baseHandlers.map(x => x.warn)
		),
		info: LevelLogFactory(
			'info',
			name,
			baseHandlers.map(x => x.info)
		),
		debug: LevelLogFactory('debug', name, isProduction ? [] : baseHandlers.map(x => x.debug)),
		error: LevelLogFactory(
			'error',
			name,
			baseHandlers.map(x => x.error)
		)
	}
}

const loggerStore: { [x: string]: NamedLoggerInstance } = { default: NamedLoggerFactory('Default') }

export const getLogger = (name = 'default'): NamedLoggerInstance => {
	return loggerStore[name] || (loggerStore[name] = NamedLoggerFactory(name))
}
