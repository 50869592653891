import { UserType } from 'services/Authentication'
import { CreditBucket } from 'lib/types/creditScore'

interface CustomUserProperties {
	user_type: UserType
	credit_score: CreditBucket
}

export const setUserId = (id: string) => {
	try {
		if (window.gtag != undefined) {
			gtag('set', { user_id: id })
		}
	} catch (e) {}
}

export const setUserProperties = (props: Partial<CustomUserProperties>) => {
	try {
		gtag('set', 'user_properties', { ...props })
	} catch (e) {}
}
