export class FionaAPIError extends Error {
	constructor(message?: string) {
		super(message)
		this.name = 'FionaAPIError'
	}
}

export class UnknownApiResponseError extends FionaAPIError {
	private statusCode: number
	private statusText: string
	private url: string
	constructor(response: Response) {
		super(`Unknown error in Fiona API Client with status ${response?.status} from ${response?.url}`)
		this.name = 'UnknownApiResponseError'
		this.statusCode = response?.status
		this.statusText = response?.statusText
		this.url = response?.url
	}
}

export class UnauthenticatedError extends FionaAPIError {
	constructor() {
		super('401 Unauthenticated from Fiona API')
		this.name = 'UnauthenticatedError'
	}
}

export class UnauthorizedError extends FionaAPIError {
	constructor() {
		super('403 Unauthorized from Fiona API')
		this.name = 'UnauthenticatedError'
	}
}
export class HttpNotFound extends Error {
	public responseBody: Record<string, any>

	constructor(body: Record<string, any>) {
		super('404 Not Found from network call')
		this.name = 'HttpNotFound'
		this.responseBody = body.error
	}
}

export class HttpBadRequest extends Error {
	public responseBody: Record<string, any>
	public message: string
	constructor(body: Record<string, any>) {
		super('400 Bad Request from network call')
		this.name = 'BadRequest'
		this.responseBody = body.error
		this.message = body?.error?.message || 'BadRequest'
	}
}

export class HttpConflict extends Error {
	private url: string

	constructor(response: Response) {
		super('409 Conflict from network call')
		this.url = response?.url
		this.name = 'HttpConflict'
	}
}

export class HttpUnauthorized extends Error {
	private url: string

	constructor(response: Response) {
		super('403 unauthorized from network call')
		this.url = response?.url
		this.name = 'Unauthorized'
	}
}

export class TimeoutError extends FionaAPIError {
	constructor() {
		super('Timeout Reached')
		this.name = 'TimeoutError'
	}
}

export class NotImplementedError extends Error {
	constructor() {
		super('NotImplementedError')
		this.name = 'NotImplementedError'
	}
}
