import React from 'react'
import { Theme, ThemeProvider } from '@emotion/react'
import { BaseTheme } from '../index'
import { DeepPartial, deepUpdate } from 'lib/objects/deepPartial'
import { Optional } from 'lib'

/**
 * The base theme context provider, must always exist.
 * @param children
 * @constructor
 */
export const RootThemeProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	return <ThemeProvider theme={BaseTheme}>{children}</ThemeProvider>
}

export type DomainTheme = DeepPartial<Theme>

const adjustedTheme = (newTheme: DeepPartial<Theme>) => (ancestorTheme: Theme) => deepUpdate(ancestorTheme, newTheme)

interface DomainThemeProps {
	theme: Optional<DomainTheme>
}

/**
 * Used to override theme values for a given Domain Theme, like 'LoanOffer' or 'LoanSummaryCard'
 * @param domainTheme
 * @constructor
 */
export const DomainThemeProvider: React.FC<React.PropsWithChildren<DomainThemeProps>> = ({ children, theme }) => {
	if (theme === undefined) return <>{children}</>
	return <ThemeProvider theme={adjustedTheme(theme)}>{children}</ThemeProvider>
}

/**
 * Used to override theme values for specific UI themes without constructing a new domain theme
 * @param domainTheme
 * @constructor
 */
export const LocalThemeProvider: React.FC<React.PropsWithChildren<DeepPartial<Theme>>> = ({ children, ...themes }) => {
	return <ThemeProvider theme={adjustedTheme(themes)}>{children}</ThemeProvider>
}
