import * as CSS from 'csstype'

type CssVar = `--${string}`

export type CssVarProperties = CSS.Properties & {
	[index: CssVar]: string
}

export enum GLOBAl_CSS_VARS {
	COMPONENT_CONTEXT = 'component-context'
}

export const setCssVar = (key: string, value: string): CssVarProperties => ({ [ensureCssPrefix(key)]: value })

export const composeCssVars = (...vars: CssVarProperties[]) => vars.reduce((acc, cur) => ({ ...acc, ...cur }))

export const getCssVar = (name: string, fallback?: string) =>
	`var(${ensureCssPrefix(name)}${fallback ? `, ${fallback}` : ''})`

const ensureCssPrefix = (key: string): CssVar => `--${key.replace(/^[-]+/, '')}`
