import { KeycloakProfile } from 'keycloak-js'

/**
 *  Describes the state of the user account (subject) based on particular details WRT the current token set
 *
 *  @param unknown - There are no tokens or history
 *  @param anonymous - An account has been generated but it does not contain credentials or an email and the user does not know it exists
 *  @param loggedIn - The account has credentials and the user has provided them in a way that their access is still valid
 *  @param loggedOut - The account is known, however it's access tokens have expired
 */
export type UserType = 'anonymous' | 'loggedIn' | 'loggedOut' | 'unknown'

/**
 * Describes the states that the Auth system can be in
 *
 * @param default - The intiial state that Auth starts in or is reset to after a logout/expiry
 * @param initializing - The state used to indicate that keycoak has started initializing and to wait rather than kick off another init
 * @param authenticated - Initialization is compete and valid tokens exist, either via SSO or anonymous user creation
 * @param expired - Indicates that while tokens/cookies remain to tell us the user's metadata, the user needs to re-login or generate anon creds
 * @param error - The Auth System is in a non-recoverable error state
 * @param disabled - Used to prevent auth from initializing if the user is about to leave the page to login.
 */
export type AuthStates = 'authenticated' | 'initializing' | 'error' | 'default' | 'disabled' | 'expired'

/**
 * The auth roles provided at the realm level, used for auth, as opposed to UI treatments {@link UserType}
 */
export type UserRoles = 'guest' | 'standard' | 'admin'

export interface UserProfile extends KeycloakProfile {
	displayName?: string
	email?: string
	role?: UserRoles
	daysSinceSignup?: number
}

export type UserInfo = {
	userType: UserType
	profile: UserProfile
	id?: string
	features: Array<string>
}

export enum AuthAlerts {
	API_401 = '401',
	API_403 = '403',
	REFRESH_FAIL = 'refresh_fail'
}

interface Attributes {
	identityProviders?: string[]
}

export interface KeycloakProfileWithAttributes extends KeycloakProfile {
	attributes: Attributes
}

export const DefaultServerUserProfile: UserInfo = { profile: {}, userType: 'unknown', features: [] }
