import isNode from 'detect-node'
import { SortingDirection } from 'types/widget'
import { Partner } from 'services/ContentfulService/models/partner/partner'
import { Asset } from 'services/ContentfulService/models/asset'
import { Optional } from './types'

export * from './promiseHelpers'
export * from './types'
export * from './system/device'

export const urlSerialize = (obj: any): string => {
	if (typeof obj !== 'object') {
		return encodeURIComponent(obj)
	}

	return Object.keys(obj)
		.filter(key => obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null)
		.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
		.join('&')
}

export const stripHTML = (text: string): string => {
	return text.replace(/<[^>]*>?/gm, '')
}

export const replaceLinks = (markdown: string): string => {
	const regex = /\[([^\]]*)\]\(([^\s^\)]*)[\s\)]/g
	return markdown.replace(regex, '<a href="$2" target="_blank">$1</a>')
}

export const calculateReadingTime = (text: string): string => {
	const length = stripHTML(text).split(' ').length
	const time = Math.round(length / 180)
	return String(time)
}

export const getQueryParam = (param: string | string[]): string[] => {
	return Array.isArray(param) ? param : [param]
}

export const removeNonNumericChars = (value: string) => {
	return value?.replace(/[^0-9]/g, '')
}

const formatToDecimal = (value: string) => {
	return value?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const formatCurrency = (input: string): string => {
	const amount = removeNonNumericChars(input)
	return formatToDecimal(amount)
}

export const convertNumberToString = (value: string | number) => {
	return typeof value === 'string' ? value : value.toString()
}

/**
 *	Used for offer sorting
 *  @param { number | null } firstNumber
 *  @param { number | null } secondNumber
 *  @param { SortingDirection } direction
 */
export const numberCompare = (
	firstNumber?: number | null,
	secondNumber?: number | null,
	direction: SortingDirection = SortingDirection.Descending
): number => {
	const directionValue = direction === SortingDirection.Ascending ? -1 : 1

	if (!firstNumber) {
		return directionValue
	}

	if (!secondNumber) {
		return -directionValue
	}

	if (firstNumber > secondNumber) {
		return -directionValue
	}

	if (firstNumber < secondNumber) {
		return directionValue
	}

	return 0
}

export const validateZipcode = (zipCode: Optional<string>): boolean => {
	if (!zipCode) return false
	return /(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)/.test(zipCode)
}

export const validateLoanAmount = (amount: Optional<number>): boolean => {
	if (!amount) return false
	if (isNaN(amount)) return false
	return amount <= 250000 && amount >= 1000
}

export const isServer = (): boolean => isNode || typeof window === 'undefined'

export const isBrowser = (): boolean => typeof window !== 'undefined'

export const formatCurrencyLocale = (value: number | string | undefined): string => {
	const num = typeof value == 'number' ? value : Number(value)

	return `$${num.toLocaleString(undefined, {
		currency: 'USD',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0
	})}`
}

interface EmailValidation {
	isValid: boolean
	errorMessage: string | undefined
}

export const capitalizeFirstLetter = (value?: string): string => {
	if (!value) {
		return ''
	}
	return value.charAt(0).toUpperCase() + value.slice(1)
}

export const validateEmail = (email: string): EmailValidation => {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	let obj: EmailValidation = {
		isValid: false,
		errorMessage: undefined
	}

	if (email.length === 0) {
		obj['errorMessage'] = 'Please enter your email'
	}
	if (!re.test(email.toLowerCase())) {
		obj['errorMessage'] = 'Please enter a valid email address'
	}

	if (email.length && re.test(email.toLowerCase())) {
		obj = {
			...obj,
			isValid: true
		}
	}

	return obj
}

interface PasswordValidation {
	isLengthValid: boolean
	hasUppercase: boolean
	hasNumber: boolean
	isValid: boolean
}

export const validatePassword = (password: string): PasswordValidation => {
	const validation = {
		isLengthValid: password.length >= 8,
		hasUppercase: /[A-Z]/.test(password),
		hasNumber: /[0-9]/.test(password)
	}
	return {
		...validation,
		isValid: Object.values(validation).every(item => item === true)
	}
}

export const noOp = () => {
	// do nothing
}
export const asyncNoOp = async () => {
	// do nothing
}

/**
 * Creates an ID string random enough for use in lists, etc
 * @constructor
 */
export const ID = (prefix = ''): string => {
	// Math.random should be unique because of its seeding algorithm.
	// Convert it to base 36 (numbers + letters), and grab the first 9 characters
	// after the decimal.
	return prefix + '_' + Math.random().toString(36).substr(2, 9)
}

/**
 * returns a typesafe slice of on an array, or the whole array, if not big enough.
 * Passing Undefined means you want the original array.
 * @param {T[] | undefined} array
 * @param {number} size
 * @return {T[]}
 */
export const truncArray = <T>(array: T[] | undefined = [], size?: number): T[] =>
	size && array.length > size ? array.slice(0, size) : array

export const howLongSince = (startTime: number, unit: 's' | 'ms' = 's'): number => {
	const ms = Date.now() - startTime
	switch (unit) {
		case 's':
			return ms / 1000
		case 'ms':
			return ms
	}
}

export const sortPartners = (a: Partner, b: Partner): number => {
	return a.displayRank - b.displayRank
}

export const calcMultiplier = (asset: Asset, base: number): number => {
	const multiplier = (asset.aspectRatio || 1) < 4 ? 0.9 : 1.2
	return Math.round(multiplier * base)
}
