import { css } from '@emotion/react'
import { getCssVar } from '../../../../lib/css/CssVarProperties'

export const SpacePropertiesStyle = css`
	--space-base: var(--space);

	--space-big: calc(var(--space) + var(--step));
	--space-bigger: calc(var(--space-big) + var(--step));
	--space-biggest: calc(var(--space-bigger) + var(--space));

	--space-small: calc(var(--space) - var(--step));
	--space-smaller: max(calc(var(--space-small) - var(--step)), 0.25rem);
`

export const SpaceSettingStyle = css`
	--step: 0.5rem;
	--space: 1rem;

	@media only screen and (max-width: 768px) {
		// Mobile drops half one step on the scale
		--space: 0.85rem;
		--step: 0.25rem;
	}
`

export const SpaceProperties = {
	Smaller: getCssVar('--space-smaller'),
	Small: getCssVar('--space-small'),
	Base: getCssVar('--space-base'),
	Big: getCssVar('--space-big'),
	Bigger: getCssVar('--space-bigger'),
	Biggest: getCssVar('--space-biggest')
}

export const SpaceMultiple = (multiplier: number) => `calc(${multiplier} * ${SpaceSettings.Space})`

const SpaceSettings = {
	Step: getCssVar('--step'),
	Space: getCssVar('--space')
}
