import { getEnv } from 'lib/envWrapper'
import { Action, ContainerComponent, createContainer, createHook, createStore } from 'react-sweet-state'
import { BrowserStorage } from 'services/common/LocalStorage'
import { addDDError } from 'services/AnalyticsLite/Datadog/events'
import { Empty, Optional } from 'lib'
import { PropsWithChildren } from 'react'

const LOCATION_STORAGE_KEY = 'fc_geo_ip'

interface LocationData {
	city?: string
	country?: string
	state?: string
	stateCode?: string
	postalCode?: string
	metroCode?: string
}

export const tryGetLocationFromStorage = (): Optional<SessionMeta> => {
	const cachedState = BrowserStorage.Session.get(LOCATION_STORAGE_KEY)
	if (cachedState && typeof cachedState === 'object' && Object.keys(cachedState).includes('Postal-Code')) {
		return mapProps(cachedState, SessionStatuses.SessionStorage)
	}
	return undefined
}

const fetchLocation = async (): Promise<LocationData> => {
	const response = await fetch(`${getEnv().api_base}/api/lambdas/location`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		}
	})
	return response.json()
}

const mapProps = (original: Record<string, any>, source: SessionStatuses): SessionMeta => ({
	location: {
		metroCode: original['Metro-Code'],
		city: original['City'],
		country: original['Country'],
		postalCode: original['Postal-Code'],
		stateCode: original['Country-Region'] ?? original['Region-Code'],
		state: original['Country-Region-Name'] ?? original['Region']
	},
	timezone: original['Time-Zone'],
	ipAddress: original['ipAddress'],
	status: source
})

export enum SessionStatuses {
	Default,
	SessionStorage,
	LambdaSourced,
	Error
}

interface SessionMeta {
	location?: LocationData
	timezone?: string
	ipAddress?: string
	status: SessionStatuses
}

const initialState = () => tryGetLocationFromStorage() ?? { status: SessionStatuses.Default }
const actions = {
	update:
		(ignoreSession = false): Action<SessionMeta> =>
		async ({ setState }) => {
			if (!ignoreSession) {
				const cachedState = tryGetLocationFromStorage()
				if (cachedState) {
					setState({ ...cachedState, status: SessionStatuses.SessionStorage })
					return
				}
			}
			fetchLocation()
				.then(res => {
					BrowserStorage.Session.set(LOCATION_STORAGE_KEY, res)
					setState(mapProps(res, SessionStatuses.LambdaSourced))
				})
				.catch(err => {
					addDDError(err)
					setState({ status: SessionStatuses.Error })
				})
		}
}

export const LocationStore = createStore<SessionMeta, typeof actions>({
	initialState: initialState(),
	actions,
	name: 'SessionMetadata'
})

export const useLocation = createHook(LocationStore, {
	selector: (state: SessionMeta) => ({ location: state.location, status: state.status })
})
export const useIpAddress = createHook(LocationStore, {
	selector: (state: SessionMeta) => ({ ipAddress: state.ipAddress, status: state.status })
})

export const SessionMetaContainer: ContainerComponent<PropsWithChildren<Empty>> = createContainer(LocationStore, {
	onInit:
		() =>
		({ getState, dispatch }) => {
			if (getState()?.location === undefined) dispatch(actions.update())
		},
	displayName: 'SessionMetadataContainer'
})
