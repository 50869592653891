// Keycloak singleton
import { KeycloakInstance } from 'keycloak-js'
import { getEnv } from 'lib/envWrapper'
import { getKeycloakMock } from 'services/Authentication/keycloak/Mock'
import { KeycloakLogger } from 'services/Logger'
import { isServer } from 'lib'

let keycloakInstance: KeycloakInstance

const Keycloak = !isServer() ? require('keycloak-js') : null

const keycloakConfig = {
	realm: getEnv().keycloak?.realm,
	url: getEnv().keycloak?.host,
	clientId: getEnv().keycloak?.clientId
}

export const createDefaultKeycloak = (): KeycloakInstance => {
	if (isServer()) {
		return getKeycloakMock()
	} else {
		if (!keycloakInstance) {
			keycloakInstance = new Keycloak(keycloakConfig)
			KeycloakLogger.debug('Keycloak Instance Returned')
		}
		return keycloakInstance
	}
}
