import { UserType } from 'services/Authentication'

export class FionaAuthError extends Error {
	constructor(message?: string) {
		super(message)
		this.name = 'FionaAuthError'
	}
}

// export class InitAndFlushAuthError extends FionaAuthError {
// 	private operation: string
// 	private responseCode: number
// 	constructor(operation: string, responseCode: number) {
// 		super(`Unknown error in Fiona API Client for ${operation}. Returned ${responseCode}`)
// 		this.name = 'InitAndFlushAuthError'
// 		this.operation = operation
// 		this.responseCode = responseCode
// 	}
// }

export class KeycloakNotRegistered extends FionaAuthError {
	constructor() {
		super('Could not acquire keycloak reference')
		this.name = 'KeycloakNotRegistered'
	}
}

export class UnknownAuthError extends FionaAuthError {
	constructor(message = '') {
		super(`Unknown auth related error occurred: ${message}`)
		this.name = 'UnknownAuthError'
	}
}

export class RefreshTokenError extends FionaAuthError {
	constructor(lastKnownType: UserType) {
		super('Could not refresh access token for user type: ' + lastKnownType)
		this.name = 'RefreshTokenError'
	}
}

export class InitAndFlushAuthError extends FionaAuthError {
	constructor() {
		super('Error initializing keycloak and flushing token queries')
		this.name = 'InitAndFlushAuthError'
	}
}

export class TokenFunctionUndefined extends FionaAuthError {
	constructor() {
		super('No handler registered for getToken')
		this.name = 'TokenFunctionUndefined'
	}
}

export class TokenUndefined extends FionaAuthError {
	constructor() {
		super('Token handler returned null for getToken')
		this.name = 'TokenUndefined'
	}
}

export class FionaAuthStatus extends Error {
	constructor(message?: string) {
		super(message)
		this.name = 'FionaAuthStatus'
	}
}

export class UnauthenticatedStatus extends FionaAuthStatus {
	constructor() {
		super('User was not authenticated')
		this.name = 'UnauthenticatedStatus'
	}
}
