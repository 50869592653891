import React from 'react'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { Global } from '@emotion/react'
import Head from 'next/head'
import { normalize } from 'lib/css/normalize'
import { AddTrustedSiteObserver } from 'lib/observers'
import { SWRConfig } from 'swr'
import { useObserver } from 'lib/hooks/useObserver'
import { getEnv } from 'lib/envWrapper'
import { AuthProvider } from 'services/Authentication'
import { RootThemeProvider } from 'components/patterns/themes/context/ThemeProvider'
import { ContentfulContainer } from 'stores/Contentful'
import { AnalyticsProvider } from 'services/AnalyticsLite/provider'
import { AuthStoreProviders } from 'stores/StoreProviders/AuthStoreProviders'
import { SessionMetaContainer } from 'services/FionaAPI/Lambdas/useLocation'
import { initDatadogRUM } from 'services/AnalyticsLite/Datadog/vendor'
import { Resource } from 'services/ContentfulService/models/resource/resource'
//TODO: Import from just 'reshaped' once off Babel and CJS
import { Reshaped } from 'reshaped/bundle'
//TODO: Remove once off Babel and CJS
import 'reshaped/bundle.css'
import '../ui/reshaped/theme/fiona/theme.css'
import '../ui/reshaped/theme/fragments/fionaBrand/theme.css'
import '../ui/reshaped/theme/fragments/fionaAccent/theme.css'

const env = getEnv()
initDatadogRUM()
const App = ({ pageProps, Component }: AppProps<{ resources: Array<Resource> }>) => {
	const router = useRouter()

	useObserver(AddTrustedSiteObserver, env.environment === 'production')
	return (
		<>
			<Head>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" key="viewport" />
			</Head>
			<Global styles={normalize} />
			<Reshaped defaultTheme="fiona" theme="fiona" defaultColorMode="light">
				<RootThemeProvider>
					<ContentfulContainer scope={router.pathname} initialResources={pageProps.resources}>
						<SessionMetaContainer isGlobal>
							<SWRConfig value={{ revalidateOnFocus: false }}>
								<AnalyticsProvider>
									<AuthProvider>
										<AuthStoreProviders>
											<Component {...pageProps} />
										</AuthStoreProviders>
									</AuthProvider>
								</AnalyticsProvider>
							</SWRConfig>
						</SessionMetaContainer>
					</ContentfulContainer>
				</RootThemeProvider>
			</Reshaped>
		</>
	)
}

export default App
