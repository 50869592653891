import { BlockComponent } from 'lib'
import React from 'react'
import Script from 'next/script'
import { GTAG_INIT_JS, GTM_HOST, GTM_INIT, GTM_WEB_ID } from 'services/AnalyticsLite/GA4/constants'
import { getEnv } from 'lib/envWrapper'

const env = getEnv()

export const GA4Scripts: BlockComponent = () => {
	return (
		<>
			<script type={'text/javascript'}>{GTAG_INIT_JS}</script>
			{env.enable_analytics && (
				<Script id={'gtm_init_script'} strategy={'lazyOnload'} type={'text/javascript'}>
					{GTM_INIT}
				</Script>
			)}
		</>
	)
}

export const GTMBodyFrame: BlockComponent = () => (
	<noscript>
		{env.enable_analytics && (
			<iframe
				src={`https://${GTM_HOST}/ns.html?id=${GTM_WEB_ID}`}
				height="0"
				width="0"
				style={{ display: 'none', visibility: 'hidden' }}
			/>
		)}
	</noscript>
)
