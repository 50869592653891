import { KeycloakInitOptions } from 'keycloak-js'
import { isKeycloakOnSameDomain } from 'lib/envWrapper'
import { buildAbsoluteUrl } from 'lib/routing/absoluteUrls'

const localConfig = {
	promiseType: 'native',
	//	redirectUri: buildAbsoluteUrl('/'),
	checkLoginIframe: false,
	checkLoginIframeInterval: 10,
	enableLogging: true
	//silentCheckSsoRedirectUri: buildAbsoluteUrl('/api/web/check-sso')
} as Partial<KeycloakInitOptions>

const sameDomainConfig = {
	redirectUri: buildAbsoluteUrl('/dashboard'),
	promiseType: 'native',
	checkLoginIframe: true,
	enableLogging: true,
	silentCheckSsoFallback: true,
	silentCheckSsoRedirectUri: buildAbsoluteUrl('/api/web/check-sso')
} as Partial<KeycloakInitOptions>

export const defaultInitConfig = isKeycloakOnSameDomain ? sameDomainConfig : localConfig
