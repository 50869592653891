import { Resource } from 'services/ContentfulService/models/resource/resource'
import { Action, createContainer, createStore } from 'react-sweet-state'
import { PropsWithChildren } from 'react'

export interface ContentfulState {
	resources: Resource[]
}

const initialState: ContentfulState = {
	resources: []
}
type Actions = typeof actions
const actions = {
	loadResources:
		(resources: Resource[]): Action<ContentfulState> =>
		({ setState }) => {
			setState({
				resources
			})
		}
}

export const ContentfulStore = createStore<ContentfulState, Actions>({
	initialState,
	actions,
	name: 'contentful'
})

type ContainerProps = { initialResources?: Resource[] }

export const ContentfulContainer = createContainer<ContentfulState, Actions, PropsWithChildren<ContainerProps>>(
	ContentfulStore,
	{
		onInit:
			() =>
			({ setState }, { initialResources }) => {
				setState({ resources: initialResources || [] })
			},
		onUpdate:
			() =>
			({ setState }, { initialResources }) => {
				setState({ resources: initialResources || [] })
			}
	}
)
