import { ID, Nullable } from 'lib'
import { SuspenseNullState } from 'components/patterns/suspense/errors'
import { DataResolver, DataWrapper, PendingStatus } from 'components/patterns/suspense/internals/types'

/**
 * Data wrappers encapsulate a promise and expose static values, so no need to manage via state
 * @param handler
 * @param id
 */
export const createDataWrapper = <T>(handler: DataResolver<Nullable<T>>, id = ID()): DataWrapper<T> => {
	let result: Nullable<T> = null
	let status: PendingStatus = PendingStatus.loading
	const suspender = handler(id)
	suspender.then(value => {
		result = value
		status = PendingStatus.complete
	})
	suspender.catch(err => {
		result = err
		status = PendingStatus.error
	})

	return {
		read(): T {
			switch (status) {
				case PendingStatus.loading:
					throw suspender
				case PendingStatus.error:
					throw result
				case PendingStatus.complete:
					if (result != null) {
						return result
					} else {
						throw new SuspenseNullState(id)
					}
			}
		},
		getStatus(): PendingStatus {
			return status
		},
		getId(): string {
			return id
		},
		fork(effect) {
			const runner = (value: Nullable<T>) => (value != null ? effect(value) : null)
			if (status === PendingStatus.loading) {
				return createDataWrapper(() => suspender.then(runner), id)
			} else if (result != null) {
				return createStaticDataWrapper(effect(result), id)
			}
			throw new SuspenseNullState(id)
		},
		onComplete() {
			return new Promise((resolve, reject) => {
				const readSafely = () => {
					try {
						const value = this.read()
						if (value) resolve(value)
					} catch (e) {
						reject(e)
					}
				}

				if (this.getStatus() === PendingStatus.loading) {
					suspender.finally(() => {
						readSafely()
					})
				} else {
					readSafely()
				}
			})
		}
	}
}

export const createLoadingDataWrapper = <T>(): DataWrapper<T> => {
	return {
		read(): T {
			throw new Promise(() => {
				// do nothing
			})
		},
		getStatus(): PendingStatus {
			return PendingStatus.loading
		},
		getId(): string {
			return ID()
		},
		fork(effect) {
			return createLoadingDataWrapper<ReturnType<typeof effect>>()
		},
		onComplete() {
			return new Promise(() => {
				// do nothing
			})
		}
	}
}

export const createStaticDataWrapper = <T>(value: T, id: string = ID()): DataWrapper<T> => {
	return {
		read(): T {
			return value
		},
		getStatus(): PendingStatus {
			return PendingStatus.complete
		},
		getId(): string {
			return id
		},
		fork(effect) {
			return createStaticDataWrapper(effect(value), id)
		},
		onComplete() {
			return Promise.resolve(value)
		}
	}
}
