import { getLogger } from 'services/Logger/NamedLogger'

export const ContentfulLogger = getLogger('contentful')
export const KeycloakLogger = getLogger('Keycloak')
export const FionaApiLogger = getLogger('Fiona API')
export const SearchLogger = getLogger('Native App Services')
export const GooglePlacesLogger = getLogger('Google Places')

const Logger = getLogger()
export default Logger
