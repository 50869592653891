export type DataResolver<Result> = (id: string) => Promise<Result>

export enum PendingStatus {
	loading = 'loading',
	complete = 'complete',
	error = 'error'
}

type EffectFn<T> = <Y>(effect: (arg: T) => Y) => DataWrapper<Y>

export interface DataWrapper<T> {
	read: () => T
	getId: () => string
	getStatus: () => PendingStatus
	fork: EffectFn<T>
	onComplete: () => Promise<T>
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type DefaultObject = {}

/**
 * Takes an arbitrary Union Type and infers the types used at call time if they exist, otherwise never / void as appropriate.
 * It works by distributing the Union into a function, which supports Infer so long as we short circuit impossible cases  to never.
 * This results in us extracting all valid types into a strictly typed intersection which can be used for type narrowing.
 */
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

/**
 * Require T or assert T will never exist depending on the pending property. Useful for skeleton react props.
 */
type PendingOrNothing<T> = (T & { pending?: false | undefined }) | ({ pending: true } & { [K in keyof T]?: never })

/**
 * Retaining the discriminated union based on the pending field that conditionally requires T,
 * Allow an optional generic R at call time who is required at all times.
 * Useful for properties that are required for layout, or would also affect skeletons
 */
export type Pending<T, R = DefaultObject> = UnionToIntersection<R> & PendingOrNothing<T>
