import { getEnv } from 'lib/envWrapper'
import { VoidCallback } from 'types/index'

const env = getEnv()
const baseConfig = {}
let started = false
let ready = false

const pending: VoidCallback[] = new Array<VoidCallback>()

export const initDDLogger = async (): Promise<void> => {
	if (ready) {
		return Promise.resolve()
	}
	if (typeof window !== 'undefined') {
		if (!started) {
			started = true
			return new Promise<void>((resolve, reject) => {
				import('@datadog/browser-logs').then(({ datadogLogs }) => {
					datadogLogs.init({
						clientToken: env.datadog?.client_token,
						datacenter: 'us',
						forwardErrorsToLogs: false,
						sampleRate: 100,
						version: env.docker_version,
						service: 'svc-fiona-web',
						env: env.environment
					})
					datadogLogs.setLoggerGlobalContext(baseConfig)
					const timeout = setTimeout(() => reject(), 5000)
					datadogLogs.onReady(() => {
						clearTimeout(timeout)
						ready = true
						pending.forEach(fn => fn())
						resolve()
					})
				})
			})
		}
	}
	return new Promise<void>(resolve => pending.push(resolve))
}
