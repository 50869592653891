import { AuthContextProps } from 'services/Authentication/context/context'
import { NotImplementedError } from 'services/FionaAPI/templates/errors'
import { TokenFunctionUndefined, TokenUndefined } from 'services/Authentication/Utils/errors'
import { AuthAlerts } from './types'
import { KeycloakLogger } from 'services/Logger'

/**
 * Used to expose functions provided by the auth context to non-react componnets
 */
class AuthState {
	private static instance: AuthState

	private constructor() {
		this.alert = () => {
			throw new NotImplementedError()
		}
		this.requestInitOnce = () => {
			throw new NotImplementedError()
		}
		this.getCurrentToken = () => {
			throw new NotImplementedError()
		}
		this.reset = () => {
			throw new NotImplementedError()
		}
	}

	public setFunctions(props: AuthContextProps) {
		this.alert = props.reportError
		this.requestInitOnce = props.requestInit
		this.getCurrentToken = props.getToken
		this.reset = async () => {
			await props.requestInit(true)
		}
	}

	public alert: (type: AuthAlerts) => Promise<void>
	public requestInitOnce: () => Promise<void>
	public getCurrentToken: () => string
	public reset: () => Promise<void>

	public getTokenSync = (): string => {
		if (!this.getCurrentToken) {
			throw new TokenFunctionUndefined()
		}
		const token = this.getCurrentToken()
		if (token) return token
		throw new TokenUndefined()
	}

	public getToken = async (): Promise<string> => {
		try {
			const token = this.getCurrentToken()
			if (token) return token
		} catch (err) {}
		KeycloakLogger.debug('Initializing since token was requested with await')
		await this.requestInitOnce()
		return this.getCurrentToken()
	}

	public static getInstance(): AuthState {
		if (!AuthState.instance) {
			AuthState.instance = new AuthState()
		}
		return AuthState.instance
	}
}

export const ExternalAuthState = AuthState.getInstance()
export default ExternalAuthState
